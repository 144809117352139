import React from 'react';
import './carousel.scss'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import IMG1 from '../../assets/images/art-gallery-design/Home-Wireframe.png'
import IMG2 from '../../assets/images/art-gallery-design/About-Page-Wireframe.png'
import IMG3 from '../../assets/images/art-gallery-design/Contact-Form-Wireframe.png'
import IMG4 from '../../assets/images/art-gallery-design/Gallery-Page-Wireframe.png'
import IMG5 from '../../assets/images/art-gallery-design/High-Level-Design.png'
import IMG6 from '../../assets/images/art-gallery-design/About-Level-Design.png'
import IMG7 from '../../assets/images/art-gallery-design/portfolio-high-level-design.png'
import IMG8 from '../../assets/images/art-gallery-design/open-image-high-level-design.png'

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
const CarouselAG = () => {
   
        
  return (
    <div className='carousel__block'>
        <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={IMG1} alt="image1" /> </SwiperSlide>
        <SwiperSlide><img src={IMG2} alt="image2" /></SwiperSlide>
        <SwiperSlide><img src={IMG3} alt="image3" /></SwiperSlide>
        <SwiperSlide><img src={IMG4} alt="image4" /></SwiperSlide>
        <SwiperSlide><img src={IMG5} alt="image5" /></SwiperSlide>
        <SwiperSlide><img src={IMG6} alt="image6" /></SwiperSlide>
        <SwiperSlide><img src={IMG7} alt="image7" /></SwiperSlide>
        <SwiperSlide><img src={IMG8} alt="image8" /></SwiperSlide>
        
      </Swiper>
    </div>
  )
}

export default CarouselAG
