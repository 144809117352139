import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./slidingPanel.scss";
import Carousel from "../Carousel/Carousel";
import { Link } from "react-router-dom";
import { FaPhp, FaFigma, FaHtml5, FaCss3, FaUnity } from "react-icons/fa";
import { MdJavascript } from "react-icons/md";
import { TbSql } from "react-icons/tb";
import { Fade } from "react-awesome-reveal";

const SlidingPanel = () => {
  const [state, setState] = useState({
    isPaneOpen: false,
  });
  return (
    <div>
      <button
        className="see__more"
        onClick={() => setState({ isPaneOpen: true })}
      >
        See More
      </button>
      <SlidingPane
        className="slidingPane"
        isOpen={state.isPaneOpen}        
        title="Close"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <h2>Spelling Bee'z</h2>
        <br />
        <Carousel />
        <div className="text-block">
          <h3>Project Overview</h3>
          <p>
            For my final year at Uni, I had the opportunity to revisit a module
            I had completed the previous year and fully develop the project from
            the original designs to a fully functioning website. My Honours
            project investigated the effects of digital game-based learning in
            early childhood and whether learners developed reading and language
            skills better from games than from the traditional means of
            teaching. The practical side of the project was to create a fully
            functioning website that parents and children could use to play
            games and learn new words. Whilst the website is active and usable,
            it still has room for improvement.
          </p>
        </div>
        <div className="tools-used">
          <h3>Tools Used</h3>
          <div className="tools-list">
            <Fade cascade damping={0.1} direction="up" triggerOnce>
              <FaHtml5 className="icon" />
              <FaCss3 className="icon" />
              <MdJavascript className="icon" />
              <FaPhp className="icon" />
              <TbSql className="icon" />
              <FaFigma className="icon" />
              <FaUnity className="icon" />
            </Fade>
          </div>
        </div>
        <div className="buttons">
          <Fade cascade damping={0.1} direction="left" triggerOnce>
            <Link to="https://github.com/CDicksonNapier/spellingBeez" target="_blank" className="github">
            Github
            </Link>
            <Link to="https://www.spellingbeez.co.uk"   className="live__demo" target="_blank">
               Demo
            </Link>
          </Fade>
        </div>
      </SlidingPane>
    </div>
  );
};

export default SlidingPanel;
