import React from 'react'
import './portfolio.scss'
import SideNav from '../../Components/SideNav/SideNav'
import IMG1 from '../../assets/images/spelling__bees.png'
import IMG2 from '../../assets/images/art-gallery-design/portfolio-high-level-design.png'
import IMG3 from '../../assets/images/notflix__image.png'
import SlidingPanel from '../../Components/SlidingPanel/SlidingPanel'
import SlidingPanelAG from '../../Components/SlidingPanel/SlidingPanelAG'
import SlidingPanelNF from '../../Components/SlidingPanel/SlidingPanelNF'


const Portfolio = () => {
  return (
    <div>
   <SideNav />
   <div className="portfolio__block">
        <h2>Portfolio</h2>
        <p>
          This is my portfolio. It is a little bare just now but I am working on
          projects that I will continously add here.
        </p>
        <div className="grid__list">
          <div className="grid__list-item">
            <img src={IMG1} alt="Speeling bees" className="grid__list-image" />
            <h3>Spelling Bee'z</h3>
            <p className="grid__item-paragraph">This was my Honours project at University.</p>
            <SlidingPanel  />
             
  
          </div>
          <div className="grid__list-item">
            <img src={IMG2} alt="Art Gallery" className="grid__list-image" />
            <h3>Art Portfolio Revisit</h3>
            <p className="grid__item-paragraph">
              I wanted to revisit a personal art portfolio and modernise it.
            </p>
            <SlidingPanelAG />
          </div>
          <div className="grid__list-item">
            <img src={IMG3} alt="Notflix Images" className="grid__list-image" />
            <h3>NotFlix</h3>
            <p className="grid__item-paragraph">My attempt at recreating Netflix with React. </p>
            <SlidingPanelNF />
          </div>
          {/* Add more work when it is completed */}
          {/* <div className="grid__list-item">
            <img src={IMG1} alt="Speeling bees" className="grid__list-image" />
            <h3>Spelling Bee'z</h3>
            <p>This was my Honours project at University.</p>
            <SlidingPanel />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Portfolio
