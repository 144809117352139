import React from 'react'
import './contact.scss'
import SideNav from '../../Components/SideNav/SideNav'
import { FaArtstation, FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
// import { FaDeviantart } from "react-icons/fa";
import  {useRef} from 'react'
import { Link } from 'react-router-dom';
// importing the email js library
import emailjs from '@emailjs/browser';
import Fade from 'react-awesome-reveal';
const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_f0amg3d', 'template_wb6lzwh', form.current, 'pUZZ0i0RHMENnTJmq')
        alert('Your message has been sent! I will get back to you as soon as possible.')// Alert the user that the email has been sent
        // Reset the form after the email is sent
        e.target.reset()
    }
  return (
    <>

    <SideNav />
    <div>

    
    <div className="text__zone">
    <h1>Contact Me</h1>
    <p>You can usually catch me on my socials below, or why not send me a message and I'll get back to you as soon as I can. </p>
   </div>
   {/* Contact container - Holds the forms and social icons */}
   <div className="container contact__container">
    {/* Social media contact options - deviant, instagram and artstations links all load in a new tab */}
   <div className="contact__options">
    <Fade cascade delay={10} damping={.51} >
          <article className='contact__option'>
           
            <h4>GitHub</h4>
            <h5>chrisdickson7</h5>
            <Link to="https://github.com/CDicksonNapier" target="__blank"><FaGithub className='icon' /></Link>
          </article>
          <article className='contact__option'>
           
            <h4>LinkedIn</h4>
            <h5>chrisdickson86</h5>
            <Link to ="https://www.linkedin.com/in/chrisdickson86/" target="__blank"><FaLinkedin className='icon' /></Link>
          </article>
            <article className='contact__option'>
               
                <h4>Artstation</h4>
                <h5>chrisdickson7</h5>
                <Link to="https://www.artstation.com/chrisdickson7" target="__blank">< FaArtstation  className='icon' /></Link>
            </article>    
          
          </Fade>
        </div>
        {/* Form to send an email  */}
   <form ref={form} onSubmit={sendEmail} >
          <h3>Send me a message</h3>
          <label htmlFor="name">Name:</label>
          <input type="text" name="from_name"  required />
          <label htmlFor="email">Email:</label>
          <input type="email" name="from_email"  required />
          <label htmlFor="message">Message:</label>
          <textarea name="message" id="message" rows="7"  required></textarea>
          <button type="submit" className='send__btn'>Send Message</button>
        </form>
   </div>
   </div>
    </>
    
    
  )
}

export default Contact
