import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./slidingPanel.scss";

import { Link } from "react-router-dom";
import {FaFigma, FaHtml5, FaCss3, FaReact  } from "react-icons/fa";
import { MdJavascript } from "react-icons/md";

import { Fade } from "react-awesome-reveal";
import CarouselAG from "../Carousel/CarouselAG";

const SlidingPanelAG = () => {
    const [state, setState] = useState({
        isPaneOpen: false,
      });
  return (
    <div>
      <button
        className="see__more"
        onClick={() => setState({ isPaneOpen: true })}
      >
        See More
      </button>
      <SlidingPane
        className="slidingPane"
        isOpen={state.isPaneOpen}
        width="75%"
        title="Close"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <h2>Art Portfolio Revisit</h2>
        <br />
        <CarouselAG />
        <div className="text-block">
          <h3>Project Overview</h3>
          <p>
          In my 2nd year of Uni, one of my modules included revisiting one of my past projects. The choice for this was to revisit my first year art portfolio that I created way back in my first year of college. The original project was a very basic HTML/CSS and JS and although well received it wasn't where I believed it could be. Seizing the opportunity to re-develop it, I got to use tools that I have learned over my college and university experience.
          </p>
        </div>
        <div className="tools-used">
          <h3>Tools Used</h3>
          <div className="tools-list">
            <Fade cascade damping={0.1} direction="up" triggerOnce>
              <FaHtml5 className="icon" />
              <FaCss3 className="icon" />
              <MdJavascript className="icon" />
              
              <FaFigma className="icon" />
              <FaReact  className="icon" />
            </Fade>
          </div>
        </div>
        <div className="buttons">
          <Fade cascade damping={0.1} direction="left" triggerOnce>
            <Link to="https://github.com/CDicksonNapier/art-portfolio" className="github" target="_blank">
              Github
            </Link>
            <Link to="https://www.cdicksonart.co.uk" className="live__demo" target="_blank">
              Demo
            </Link>
          </Fade>
        </div>
      </SlidingPane>
    </div>
  )
}

export default SlidingPanelAG
