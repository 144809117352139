import React from "react";
import "./about.scss";

import ME from "../../assets/images/profilePic1.jpg";

import CV from "../../assets/CV/chrisDicksonCV.pdf";

import {Link} from 'react-router-dom'

import {
  FaHtml5,
  FaPython,
  FaPhp,
  FaReact,
  FaSass,
  FaFigma,
} from "react-icons/fa";
import { MdCss, MdJavascript } from "react-icons/md";
import {
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiBlender,
} from "react-icons/si";

import SideNav from "../../Components/SideNav/SideNav";
const About = () => {
  return (
    <div>
      <SideNav />
      <div className="container__about">
      <div className="img-block">
            <img src={ME} alt="ProfilePic" />
          </div>
        <div className="text__container">
          <h1>All about me!</h1>
          <p>
          Thank you for visiting my portfolio! I recently graduated from Edinburgh Napier University with a 2:1 in Web Design and Development, where I developed a strong passion for creating websites and applications that are both visually engaging and highly functional.

I’m always eager to learn new technologies and refine my skills, and I’m currently seeking a role in web development to further my career. My technical proficiency includes HTML, CSS, JavaScript, React, and Node.js, and I enjoy combining my creativity with problem-solving to craft innovative solutions.

Outside of web development, I love exploring my creative side through 3D landscape design in Blender and digital artwork in Photoshop. I’m continuously looking for fresh ways to express my creativity and expand my skill set.
          </p>
          <div className="buttons__container">
            <button className="cv-btn">
              <a href={CV} target="__blank">
                My CV
              </a>
            </button>
            <Link to='/contact' className="contact-btn">
              Contact
            </Link>
          </div>
          <div className="skills__container">
          <h2>Frequently used tools</h2>

          <div className="skills__icons">
            <FaHtml5 className="icon" />
            <MdCss className="icon" />
            <MdJavascript className="icon" />
            <FaPython className="icon" />
            <FaPhp className="icon" />
            <FaReact className="icon" />
            <FaSass className="icon" />
            <SiAdobephotoshop className="icon" />
            <SiAdobeillustrator className="icon" />
            <FaFigma className="icon" />
            <SiBlender className="icon" />
          </div>
        </div>
        </div>
      

        
      </div>
      
    </div>
  );
};

export default About;
