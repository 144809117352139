import React from 'react';
import './carousel.scss'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import IMG1 from '../../assets/images/spelling-bees-design/home.png'
import IMG2 from '../../assets/images/spelling-bees-design/About.png'
import IMG3 from '../../assets/images/spelling-bees-design/Contact.png'
import IMG4 from '../../assets/images/spelling-bees-design/Accounts.png'
import IMG5 from '../../assets/images/spelling-bees-design/Play.png'
import IMG6 from '../../assets/images/spelling-bees-design/Animal-Buddies-Hifi.png'
import IMG7 from '../../assets/images/spelling-bees-design/Games-Hifi.png'
import IMG8 from '../../assets/images/spelling-bees-design/Home-Page-Hifi.png'
import IMG9 from '../../assets/images/spelling-bees-design/Parents-Page-Hifi.png'
import IMG10 from '../../assets/images/spelling-bees-design/Proper-Games-Screen-Hifi.png'
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
const Carousel = () => {
   
        
  return (
    <div className='carousel__block'>
        <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={IMG1} alt="image1" /> </SwiperSlide>
        <SwiperSlide><img src={IMG2} alt="image2" /></SwiperSlide>
        <SwiperSlide><img src={IMG3} alt="image3" /></SwiperSlide>
        <SwiperSlide><img src={IMG4} alt="image4" /></SwiperSlide>
        <SwiperSlide><img src={IMG5} alt="image5" /></SwiperSlide>
        <SwiperSlide><img src={IMG6} alt="image6" /></SwiperSlide>
        <SwiperSlide><img src={IMG7} alt="image7" /></SwiperSlide>
        <SwiperSlide><img src={IMG8} alt="image8" /></SwiperSlide>
        <SwiperSlide><img src={IMG9} alt="image9" /></SwiperSlide>
        <SwiperSlide><img src={IMG10} alt="image10" /></SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Carousel
