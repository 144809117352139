import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./slidingPanel.scss";

import { Link } from "react-router-dom";
import {FaFigma, FaHtml5, FaCss3, FaReact  } from "react-icons/fa";
import { MdJavascript } from "react-icons/md";
import { IoLogoFirebase } from "react-icons/io5";

import { Fade } from "react-awesome-reveal";

import CarouselNF from "../Carousel/CarouselNF";

const SlidingPanelNF = () => {
    const [state, setState] = useState({
        isPaneOpen: false,
      });
  return (
    <div>
      <button
        className="see__more"
        onClick={() => setState({ isPaneOpen: true })}
      >
        See More
      </button>
      <SlidingPane
        className="slidingPane"
        isOpen={state.isPaneOpen}       
        title="Close"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false});
        }}
      >
        <h2>Notflix (Netflix clone)</h2>
        <br />
        <CarouselNF />
        <div className="text-block">
          <h3>Project Overview</h3>
          <p>
          I am currently working on this project. Originally, it was an element from 1st year at Uni that I had to build a web based app using python and flask. However, I have chosen to completely redo the entire website using React and Firebase.
          </p>
        </div>
        <div className="tools-used">
          <h3>Tools Used</h3>
          <div className="tools-list">
            <Fade cascade damping={0.1} direction="up" triggerOnce>
              <FaHtml5 className="icon" />
              <FaCss3 className="icon" />
              <MdJavascript className="icon" />
              <IoLogoFirebase className="icon" />
              <FaFigma className="icon" />
              <FaReact  className="icon" />
            </Fade>
          </div>
        </div>
        <div className="buttons">
          <Fade cascade damping={0.1} direction="left" triggerOnce>
            <Link to="https://github.com/CDicksonNapier/notflixFinal" className="github" target="_blank">
             Github
            </Link>
            <Link to="https://notflix-22010.web.app/" target="_blank" className="live__demo">
             Demo
            </Link>
          </Fade>
        </div>
      </SlidingPane>
    </div>
  )
}

export default SlidingPanelNF
