import React, {useState, useEffect} from 'react'
import './home.scss'
import SideNav from '../../Components/SideNav/SideNav'
import ParticlesComponent from '../../Components/Particles/Particles'
import AnimatedLetters from '../../Components/AnimatedLetters/AnimatedLetters'
import { Link } from 'react-router-dom'
import LOGO from '../../assets/images/logoC.png'


const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  // setting the name array 
  const nameArray = [ 'h', 'r', 'i', 's', ' ', ' ', 'D', 'i', 'c', 'k', 's', 'o', 'n']
  
// The animated letters with dely of 4 seconds then the class is changed to text-animate-hover
  useEffect(() => {
     setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])
  return (
    <div>
      <SideNav />
      <ParticlesComponent style={{cursor: 'none'}} />
      <div className="container home-page"   >
             
             {/* Text container - that has the same animation and hover effect */}
             <div className="text-zone">
        
               <h1>
                 <span className={letterClass}>H</span>
                 <span className={`${letterClass} _12`}>i</span>
                 <span className={`${letterClass} _13`}>,</span>
                 <br />
                 <span className={`${letterClass} _14`}>I</span>
                 <span className={`${letterClass} _15`}>'</span>
                 <span className={`${letterClass} _16`}>m</span>
                 {/* Logo - this has a z rotation effect that spins on page load  */}
                 <img
                   src={LOGO}
                   alt="Initial logo"
                 />
                 {/* The animation components for the letters.  */}
                 <AnimatedLetters
                   letterClass={letterClass}
                   strArray={nameArray}
                   idx={19}
                 />
                 <br />            
               </h1>
               {/* The sub heading */}
               <h2>Front-End Developer / UI/UX Designer / Digital Designer / 3D Artist</h2>
               <br />
               {/* The CTA button -directs user to the contact page */}
               <Link to="/contact" className="flat-button">
                Lets Connect
               </Link>
             </div>     
           </div>
    </div>
  )
}

export default Home
