import React from 'react';
import './carousel.scss'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import IMG1 from '../../assets/images/notflix-designs/notflix-home.png'
import IMG2 from '../../assets/images/notflix-designs/notflix-image.png'
import IMG3 from '../../assets/images/notflix-designs/notflix-home.png'
import IMG4 from '../../assets/images/notflix-designs/notflix-lists.png'
import IMG5 from '../../assets/images/notflix-designs/notflix-movies.png'
import IMG6 from '../../assets/images/notflix-designs/notflix-signin.png'
import IMG7 from '../../assets/images/notflix-designs/notflix-tv.png'

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
const CarouselNF = () => {
   
        
  return (
    <div className='carousel__block'>
        <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={IMG1} alt="image1" /> </SwiperSlide>
        <SwiperSlide><img src={IMG2} alt="image2" /></SwiperSlide>
        <SwiperSlide><img src={IMG3} alt="image3" /></SwiperSlide>
        <SwiperSlide><img src={IMG4} alt="image4" /></SwiperSlide>
        <SwiperSlide><img src={IMG5} alt="image5" /></SwiperSlide>
        <SwiperSlide><img src={IMG6} alt="image6" /></SwiperSlide>
        <SwiperSlide><img src={IMG7} alt="image7" /></SwiperSlide>
        
      </Swiper>
    </div>
  )
}

export default CarouselNF
